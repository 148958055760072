import { Controller } from '@hotwired/stimulus'
import { showNotification } from '../../utils/bootstrap_notify';

export default class extends Controller {
  static values = { uploadUrl: String, updateUrl: String }
  static targets = [ "filesTable", "uploadArea", "modalForm",
                     "fileIdField", "fileNameField", "fileTypeField",
                     "oldNameField", "componentField", "oldTypeField" ]

  toggleFileUploadArea() {
    this.filesTableTarget.classList.toggle('d-none')
    this.uploadAreaTarget.classList.toggle('d-none')
  }

  submitFileUploadForm(e) {
    e.preventDefault()
    e.stopPropagation()

    e.target.disabled = true

    const form = document.querySelector('.submitForm')
    const url = this.uploadUrlValue
    const formData = new FormData(form)

    fetch(url, { method: "PATCH", body: formData })
      .then(response => response.text())
      .then(json => {
        const jsonObject = JSON.parse(json)
        const fixedJsonString = jsonObject.message.replace(/=>/g, ':').replace(/nil/g, 'null')
        const objectNew = JSON.parse(fixedJsonString)
        showNotification(objectNew.message, jsonObject.type)
        e.target.disabled = false

        if (jsonObject.status != 'ok') { return }

        setTimeout(() => {
          document.getElementById('files-tab').click()
        }, 1800)
      });
  }

  submitFileUpdateForm(e) {
    e.preventDefault()
    e.stopPropagation()

    e.target.disabled = true

    const form = document.getElementById('updateFileForm')
    const url = this.updateUrlValue + '?' +  new URLSearchParams(new FormData(form)).toString()

    fetch(url, { method: "PATCH" })
      .then(response => {
        if (!response.ok) {
          this.closeModal()
          showNotification('Something went wrong', 'danger')
        } else {
          window.location.reload()
        }
      })
  }

  openModal(e) {
    this.prefillModalData(e);
    this.modalFormTarget.classList.add("d-block")
    document.querySelector('.offcanvas-overlay').classList.remove('d-none')
  }

  closeModal(e) {
    this.modalFormTarget.classList.remove("d-block");
    document.querySelector('.offcanvas-overlay').classList.add('d-none')
  }

  prefillModalData(e) {
    const event = new Event("change");
    this.fileTypeFieldTarget.value = e.currentTarget.dataset.fileType;
    this.fileTypeFieldTarget.dispatchEvent(event);
    this.fileIdFieldTarget.value = e.currentTarget.dataset.fileId;
    this.fileNameFieldTarget.value = e.currentTarget.dataset.fileName;
    this.oldNameFieldTarget.value = e.currentTarget.dataset.fileName;
    this.oldTypeFieldTarget.value = e.currentTarget.dataset.fileType;
    this.componentFieldTarget.value = e.currentTarget.dataset.component;
  }
}
